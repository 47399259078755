import React from "react"

import { Link } from "gatsby"

import Logo from "../../images/uxanimation-logo.svg"
import IconDribbble from "../../images/dribbble.svg"
import IconInstagram from "../../images/instagram.svg"
import IconLottieFiles from "../../images/lottiefiles.svg"

import "./footer.scss"

const Footer = () => (
    <footer>
        <section className="footer">
            <div className="container">
                <div className="content">
                    <div className="logo">
                        <img src={Logo} alt="UXanimation"/>
                        <p>
                            Enhancing apps user experience with <br/>creative and functional Lottie animations.
                        </p>
                    </div>
                    <div className="follow">
                        <p>Follow us</p>
                        <div className="icons-container">
                            <a href="https://www.instagram.com/ux_animation/" target="blank">
                                <img src={IconInstagram} alt="Follow us on Instagram"/>
                            </a>
                            <a href="https://dribbble.com/uxanimation" target="blank">
                                <img src={IconDribbble} alt="Follow us on Dribbble"/>
                            </a>
                            <a href="https://lottiefiles.com/uxanimation" target="blank">
                                <img src={IconLottieFiles} alt="Get free animations on my LottieFiles profile"/>
                            </a>
                            
                        </div>
                    </div>
                    <p>Made with<span>♥️</span>from Brazil</p>
                </div>
            </div>
        </section>
    </footer>
)

export default Footer